/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"


import Header from "./header"
import Front from "./front"
import Box from "./box"
import Finger from "./finger"
import Footer from "./footer"
import "./layout.css"
import About from "./about"

import diz1 from "../images/diz1.png"
import diz2 from "../images/diz2.png"
import diz3 from "../images/diz3.png"
import diz4 from "../images/diz4.png"
import diz5 from "../images/diz5.png"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      allMarkdownRemark(sort: {order: ASC, fields: frontmatter___title}) {
        edges {
          node {
            frontmatter {
              title
              path
              discription
            }
          }
        }
      }
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  //console.log(data.allMarkdownRemark.edges[2].node.frontmatter)
  console.log(data.allMarkdownRemark.edges)
  const about = data.allMarkdownRemark.edges[0].node.frontmatter
  const life = data.allMarkdownRemark.edges[1].node.frontmatter
  const situation = data.allMarkdownRemark.edges[2].node.frontmatter
  const talent = data.allMarkdownRemark.edges[3].node.frontmatter
  const trade = data.allMarkdownRemark.edges[4].node.frontmatter


  return (
    <div className="font">
      <Header theme = "navbar nav-top" />
      <Front theme = "front" />
      <About data={about} />
      <Finger theme = "v2" img = {diz4} class = "image" />
      <Box theme = "box" data = {life} id="section2"/>
      <Finger theme = "v1" img = {diz1} class = "image2" />
      <Box theme = "box1" data = {trade} id="section3"/> 
      <Finger theme = "v2" img = {diz3} class = "image3" />
      <Box theme = "box" data = {talent} id="section4"/>
      <Finger theme = "v1" img = {diz5} class = "image4" />
      <Box theme = "box1" data = {situation} id="section5"/>    
     <Footer theme = "footer1"/>   
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
