import { Link, graphql, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import React, {Component} from "react"
import Img from "gatsby-image"


import "../styles/finger.css"


const finger = (props) => {

    return(
        <div>
            <img className={props.class} src={props.img} />
            <div className={(props.theme === "v1") ? "nay" : "yay" } >
            </div>
        </div>
    )
}

export default finger