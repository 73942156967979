import { Link, graphql, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import React, {Component} from "react"
import Img from "gatsby-image"

import diz1 from "../images/diz1.png"
import diz2 from "../images/diz2.png"

const About = (props) => {
    const data = useStaticQuery(graphql`
    query {
        diz1: file(relativePath: {eq: "diz1.png"}) {
          childImageSharp {
            fluid {
                ...GatsbyImageSharpFluid
            }
          }
        }
        diz2 :file(relativePath: {eq: "diz2.png"}) {
          childImageSharp {
            fluid {
                ...GatsbyImageSharpFluid
            }
          }
        }
      }
      `)
      
      console.log(props.data.title)
      

    return(
        <div>
            <div id="section1" className="about">
            <div className="finger2" >
                <img  src={diz2}/>
            </div>        
            <div>
                        <p className="heading">{props.data.title}</p>
                        <p>{props.data.discription}</p>       
            </div>                          
        </div>
        {/* <img className="finger1" src={diz1}/>
        <img className="finger2" src={diz2}/> */}
        {/* <Img className="fingerprint1" fluid={data.diz1.childImageSharp.fluid} />
        <Img className="fingerprint2" fluid={data.diz2.childImageSharp.fluid} /> */}
        </div>
        
    )
} 

export default About