import { Link, graphql, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import React, {Component} from "react"
import Img from "gatsby-image"

import diz1 from "../images/diz1.png"
import diz2 from "../images/diz2.png"

const Box = (props) => {
    const data = useStaticQuery(graphql`
    query {
        diz1: file(relativePath: {eq: "diz1.png"}) {
          childImageSharp {
            fluid {
                ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
        diz2 :file(relativePath: {eq: "diz2.png"}) {
          childImageSharp {
            fluid {
                ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
      `)
      
      
      

    return(
        <div>
          <div id={props.id} className={props.theme}>
            <div> 
                <p className="heading">{props.data.title}</p>
                <p>{props.data.discription}</p>
                <a target="_blank" href="/intro/">
                  <button className="button">Get Your Script</button>
                </a>               
            </div>  
                                    
          </div>        
        </div>
        
    )
} 

export default Box